<template>
  <div class="card-container">
    <dy-table
      :rowList="rowList"
      :dataList="dataList"
      :page="page"
      :pageSize="pageSize"
      :total="total"
      :height="'700px'"
      :showPagination="true"
      @pageChange="handlePageChange"
    >
      <template #filter>
        <el-button class="margin-right-ten" @click="handlePageChange"
          >刷新数据</el-button
        >
        <c-input
          placeholder="请输入账户名"
          class="margin-right-ten"
          v-model="advertiserName"
          style="width: 200px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handlePageChange"
          ></el-button>
        </c-input>
        <el-button
          type="primary"
          class="margin-right-ten"
          @click="handleAddCard"
          >新增</el-button
        >
      </template>
      <template #operation="row">
        <el-button type="primary" size="small" @click="handleDetail(row)"
          >关联详情</el-button
        >
        <el-button type="primary" size="small" @click="handleUpload(row.row)"
          >上传</el-button
        >
        <el-button type="warning" size="small" @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button type="danger" size="small" @click="handleDelete(row.row)"
          >删除</el-button
        >
      </template>
      <template #imageUrl="row">
        <img style="width: 50px; height: 50px" :src="row.row.imageUrl" alt="" />
      </template>
      <template #enablePersonalAction="row">
        <el-tag v-if="row.row.enablePersonalAction" type="success">是</el-tag>
        <el-tag v-else type="danger">否</el-tag>
      </template>
    </dy-table>
    <!-- 新增编辑框 -->
    <el-dialog
      title="新增编辑"
      class="edit-dialog"
      :visible.sync="showEdit"
      width="500px"
    >
      <addCard
        v-if="showEdit"
        :isEdit="isEdit"
        :editId="editId"
        :editParams="params"
        :Foptions="options"
        @handlePageChange="handlePageChange"
        @close="handleCloseEdit"
      />
    </el-dialog>
    <!-- 详情编辑框 -->
    <el-drawer
      :title="'组件详情：' + detailTitle"
      :visible.sync="showDetail"
      size="800px"
    >
      <dy-table
        :dataList="detailData"
        :rowList="detailRowList"
        :height="'500px'"
      >
        <template #status="row">
          <el-tag v-if="row.row.status === 1" type="warning">待上传</el-tag>
          <el-tag v-else-if="row.row.status === 2" type="success"
            >上传成功</el-tag
          >
          <el-tag v-else type="danger">上传失败</el-tag>
        </template>
      </dy-table>
    </el-drawer>
  </div>
</template>

<script>
import selectAccount from "../components/selectAccount";
import addCard from "../components/addCard";
import {
  getPromotionCard,
  deletePromotionCard,
  uploadPromotionCard,
} from "@/api/toutiao.js";
export default {
  data() {
    return {
      loading: false,
      rowList: [
        {
          label: "组件名称",
          prop: "componentName",
        },
        {
          label: "卡片标题",
          prop: "title",
        },
        {
          label: "行动号召",
          prop: "buttonText",
        },
        {
          label: "图片",
          prop: "imageUrl",
          slot: true,
        },
        {
          label: "是否开启智能优选",
          prop: "enablePersonalAction",
          slot: true,
        },
        {
          label: "推广卖点",
          prop: "productSellingPoints",
        },
        {
          label: "操作",
          action: "operation",
          width: "200px",
        },
      ],
      detailRowList: [
        {
          label: "账号id",
          prop: "channelId",
        },
        {
          label: "账号名称",
          prop: "advertiserName",
        },
        {
          label: "广告组id",
          prop: "advertiserId",
        },
        {
          label: "状态",
          prop: "status",
          slot: true,
        },
        {
          label: "失败原因",
          prop: "failReason",
        },
      ],
      detailData: [],
      dataList: [],
      pageSize: 15,
      page: 1,
      total: 1,
      search: null,
      advertiserName: null,
      showEdit: false,
      isEdit: false,
      showDetail: false,
      params: {},
      options: [],
      submitLoading: false,
      editId: null,
      detailTitle: "",
    };
  },
  components: {
    selectAccount,
    addCard,
  },
  methods: {
    handleCloseEdit() {
      this.showEdit = false;
    },
    handleRemoveTag(item) {
      this.options.forEach((value, index) => {
        if (value.id === item) {
          this.options.splice(index, 1);
          this.params.channelIds.splice(index, 1);
          return;
        }
      });
    },
    handleUpload(row) {
      uploadPromotionCard(row.id)
        .then((res) => {
          this.$message.success("上传成功");
        })
        .finally(() => {});
    },
    handlePageChange(page) {
      if (typeof page === "number") {
        this.page = page;
      }
      this.loading = true;
      getPromotionCard({
        page: this.page,
        pageSize: this.pageSize,
        advertiserName: this.advertiserName,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDetail(row) {
      this.detailTitle = row.row.componentName;
      this.detailData = row.row.joinChannelList;
      this.showDetail = true;
    },
    handleEdit(row) {
      this.editId = row.row.id;
      this.params = {
        buttonText: row.row.buttonText,
        componentName: row.row.componentName,
        enablePersonalAction: row.row.enablePersonalAction,
        imageUrl: row.row.imageUrl,
        productSellingPoints: row.row.productSellingPoints,
        title: row.row.title,
      };
      let tmpChannel = [];
      row.row.joinChannelList.forEach((item) => {
        tmpChannel.push(item.id);
      });
      this.$set(this.params, "channelIds", tmpChannel);
      this.options = [...row.row.joinChannelList];
      this.isEdit = true;
      this.showEdit = true;
    },
    handleDelete(row) {
      this.$confirm("确定要删除该记录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletePromotionCard(row.id)
          .then((res) => {
            this.$message.success("删除成功");
            this.handlePageChange(this.page);
          })
          .finally(() => {});
      });
    },
    handleAddCard() {
      this.params = {};
      this.options = [];
      this.isEdit = false;
      this.showEdit = true;
    },
  },
  created() {
    this.handlePageChange();
  },
};
</script>

<style lang='scss' scoped>
/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
/deep/ .account-selector .el-icon-close {
  display: none !important;
}
.upload-box {
  width: 108px;
  height: 108px;
  border: 1px dashed #e7e7e7;
  background-color: #f8f8f8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
/deep/ .edit-dialog .el-dialog__footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-box {
  position: absolute;
  top: 0;
  left: 0;
  img {
    width: 108px;
    height: 108px;
  }
}
</style>