<template>
  <el-dialog
    title="选择账号"
    top="20px"
    append-to-body
    :visible.sync="showAccount"
    :before-close="() => $emit('cancel')"
  >
    <div class="select-container">
      <el-input
        v-model="params.remark"
        placeholder="请输入备注"
        clearable
        style="width: 150px"
        class="margin-right-ten"
      ></el-input>
      <el-input
        v-model="params.name"
        placeholder="请输入账户名"
        clearable
        style="width: 150px"
        class="margin-right-ten"
      ></el-input>
      <el-button
        type="primary"
        class="margin-right-ten"
        @click="handlePageChange(1)"
        >搜索</el-button
      >
      <el-button
        type="warning"
        class="margin-right-ten"
        @click="handleChangeMarks"
        >批量修改</el-button
      >
      <el-table
        ref="selectTable"
        :data="tableData"
        tooltip-effect="dark"
        style="height: 640px; overflow-y: auto"
        @select="handleSelected"
        @select-all="handleSelecteAll"
        @selection-change="handleSelectionChange"
        @row-click="handleRowClick"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="ID" prop="id" width="80">
          <!-- <template slot-scope="scope">{{ scope.row.date }}</template> -->
        </el-table-column>
        <el-table-column prop="advertiserName" label="账户名称">
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.remark"
              closable
              @close="handleDelete(scope.row)"
            >
              {{ scope.row.remark }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click.stop="handleUpdata(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        :page-size="pageSize"
        @current-change="handlePageChange"
        :current-page="page"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- 保存取消 -->
      <div class="save-box">
        <el-button @click="$emit('cancel')">取 消</el-button>
        <el-button type="primary" @click="$emit('confirm', selectedData)"
          >确 定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getOeRemark,
  updateOemark,
  addOemark,
  deleteOemark,
} from "@/api/toutiao.js";
export default {
  props: {
    seleted: Array,
    showAccount: Boolean,
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      params: {},
      tableData: [],
      selectedData: [],
      tmpSeleted: [],
      rowclickFlag: false,
    };
  },
  watch: {
    showAccount: function (nv, ov) {
      if (nv) {
        this.selectedData = [...this.seleted];
        this.tmpSeleted = this.seleted.map((item) => {
          return {
            ...item,
          };
        });
        this.handlePageChange();
      }
    },
  },
  methods: {
    handleChangeMarks() {
      if (this.selectedData.length == 0) {
        return this.$message.error("请先勾选需要修改的账号");
      }
      let tmpIds = this.selectedData.map((item) => {
        return item.id;
      });
      this.$prompt("请输入备注", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: "",
      })
        .then(({ value }) => {
          if (!value) {
            return this.$message.error("备注不能为空");
          }
          updateOemark(tmpIds.join(","), {
            remark: value,
          }).then((res) => {
            this.$message.success("修改成功");
            this.handlePageChange(this.page);
          });
        })
        .catch(() => {});
    },
    handleRowClick(row, column, event) {
      this.rowclickFlag = true;
      this.$refs.selectTable.toggleRowSelection(row);
      // 区分是用户点击清空当前page的seleceted还是页面切换清空的
    },
    handleUpdata(item) {
      this.$prompt("请输入备注", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: item.remark || "",
      })
        .then(({ value }) => {
          if (!value) {
            return this.$message.error("备注不能为空");
          }
          if (item.remark) {
            updateOemark(item.id, {
              remark: value,
            }).then((res) => {
              this.$message.success("修改成功");
              this.handlePageChange(this.page);
            });
          } else {
            addOemark({
              id: item.id,
              remark: value,
            }).then((res) => {
              this.$message.success("新增成功");
              this.handlePageChange(this.page);
            });
          }
        })
        .catch(() => {});
    },
    handleDelete(item) {
      this.$confirm("确定要删除备注?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteOemark(item.id).then((res) => {
          this.$message.success("删除成功");
          this.handlePageChange(this.page);
        });
      });
    },
    handleSelecteAll(item) {
      this.handleSelectionChange(item, true);
    },
    handleSelected(item) {
      this.handleSelectionChange(item, true);
    },
    handleSelectionChange(item, flag) {
      if (!this.rowclickFlag && !flag) {
        return;
      }
      let tmpSelected = this.selectedData.map((item) => {
        return {
          ...item,
        };
      });
      let tmpSelectedId = this.selectedData.map((item) => {
        return item.advertiserId;
      });
      let tmpItemId = item.map((item) => {
        return item.advertiserId;
      });
      let tmptableId = this.tableData.map((item) => {
        return item.advertiserId;
      });
      // 取消勾选
      tmpSelected.forEach((item2, index2) => {
        if (
          !tmpItemId.includes(item2.advertiserId) &&
          tmptableId.includes(item2.advertiserId)
        ) {
          this.selectedData.splice(index2, 1);
        }
        // 全选反选
        if (!tmpItemId.length) {
          if (tmptableId.includes(item2.advertiserId)) {
            let tmpIndex3 = this.selectedData.findIndex((item3) => {
              return item3.advertiserId === item2.advertiserId;
            });
            if (tmpIndex3 !== -1) {
              this.selectedData.splice(tmpIndex3, 1);
            }
          }
        }
      });
      // 存入勾选
      item.forEach((item3) => {
        if (!tmpSelectedId.includes(item3.advertiserId)) {
          this.selectedData.push({
            ...item3,
          });
        }
      });
      this.rowclickFlag = false;
    },
    handlePageChange(page) {
      this.page = page || 1;
      this.loading = true;
      getOeRemark({
        page: this.page,
        pageSize: this.pageSize,
        remark: this.params.remark,
        name: this.params.name,
      })
        .then((res) => {
          this.tableData = res.list;
          this.total = res.total;
          let tmpseleted = [];
          setTimeout(() => {
            this.tableData.forEach((item1, index1) => {
              this.selectedData.forEach((item2) => {
                if (item1.advertiserId === item2.advertiserId) {
                  tmpseleted.push(index1);
                }
              });
            });
            tmpseleted.forEach((item) => {
              this.$refs.selectTable.toggleRowSelection(
                this.tableData[item],
                true
              );
            });
          }, 200);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    // this.selectedData = [...this.seleted];
    // this.tmpSeleted = this.seleted.map((item) => {
    //   return {
    //     ...item,
    //   };
    // });
    // this.handlePageChange();
  },
};
</script>

<style lang='scss' scoped>
.save-box {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>