<template>
  <div>
    <div class="edit-container">
      <el-form
        label-position="left"
        label-width="120px"
        class="form-container"
        v-loading="submitLoading"
      >
        <el-form-item
          label="推广账号"
          style="margin-bottom: 14px"
          class="necessary"
        >
          <el-popover
            :disabled="options.length === 0"
            placement="top-start"
            width="300"
            trigger="hover"
          >
            <el-tag
              :key="tag.id"
              v-for="tag in options"
              :disable-transitions="false"
              style="margin: 6px"
              closable
              @close="handleRemoveTag(tag.id)"
            >
              {{ tag.advertiserName }}
            </el-tag>
            <el-select
              :ref="'AccountSlecter'"
              v-model="params.channelIds"
              multiple
              placeholder="请选择"
              collapse-tags
              @remove-tag="handleRemoveTag(tag)"
              @focus="handleShowAccount"
              slot="reference"
              class="account-selector"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.advertiserName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-popover>
        </el-form-item>
        <el-form-item
          label="组件名称"
          style="margin-bottom: 14px"
          class="necessary"
        >
          <el-input
            type="text"
            placeholder="请输入内容"
            v-model="params.componentName"
            maxlength="10"
            show-word-limit
            style="width: 200px"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="卡片主图"
          style="margin-bottom: 14px"
          class="necessary"
        >
          <input
            type="file"
            ref="coverPic"
            style="display: none"
            @change="handleFileChange($event, 'coverPic')"
          />
          <div class="upload-box" @click="handleChangeImg">
            <i class="el-icon-plus"></i>
            <div class="img-box" v-if="params.imageUrl">
              <img :src="params.imageUrl" alt="" />
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="卡片标题"
          style="margin-bottom: 14px"
          class="necessary"
        >
          <el-input
            type="text"
            placeholder="请输入卡片标题"
            v-model="params.title"
            maxlength="7"
            show-word-limit
            style="width: 200px"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="行动号召"
          style="margin-bottom: 14px"
          class="necessary"
        >
          <el-input
            type="text"
            placeholder="行动号召2-6个字符"
            v-model="params.buttonText"
            maxlength="6"
            minlength="2"
            show-word-limit
            style="width: 200px"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="推广卖点"
          style="margin-bottom: 14px"
          class="necessary"
        >
          <el-input
            type="textarea"
            autosize
            :rows="4"
            placeholder="每个卖点6-9个字，多个以空格隔开，最多10个卖点"
            v-model="params.productSellingPoints"
            show-word-limit
            style="width: 200px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="智能优选" style="margin-bottom: 14px">
          <el-switch
            v-model="params.enablePersonalAction"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="submit-footer">
      <el-button @click="$emit('close')">取消</el-button>
      <el-button v-if="showLocal" type="warning" @click="handleSubmit(1)"
        >保存本地</el-button
      >
      <el-button type="primary" @click="handleSubmit(2)"
        >保存本地并上传</el-button
      >
    </span>
    <!-- 账号选择器 -->
    <select-account
      @cancel="showAccount = false"
      @confirm="handleSelected"
      :showAccount="showAccount"
      :seleted="options"
    />
  </div>
</template>

<script>
import { uploadFile } from "@/api/common.js";
import selectAccount from "./selectAccount";
import { addPromotionCard, updatePromotionCard } from "@/api/toutiao.js";
export default {
  components: {
    selectAccount,
  },
  props: {
    showLocal: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    editId: Number,
    editParams: Object,
    Foptions: Array,
  },
  data() {
    return {
      submitLoading: false,
      options: [],
      params: {},
      showAccount: false,
    };
  },
  methods: {
    handleSelected(item) {
      let tmpArr1 = this.options.map((item) => item.advertiserId);
      // this.options = [];
      item.forEach((value) => {
        if (!tmpArr1.includes(value.advertiserId)) {
          this.options.push(JSON.parse(JSON.stringify(value)));
        }
      });
      this.params.channelIds = this.options.map((value) => {
        return value.id;
      });
      this.showAccount = false;
    },
    handleChangeImg() {
      this.$refs.coverPic.click();
    },
    handleRemoveTag(item) {
      this.options.forEach((value, index) => {
        if (value.id === item) {
          this.options.splice(index, 1);
          this.params.channelIds.splice(index, 1);
          return;
        }
      });
    },
    handleShowAccount() {
      this.$refs["AccountSlecter"].blur();
      this.showAccount = true;
    },
    handleFileChange(e, type) {
      let targetFile = e.target.files[0];
      uploadFile({ file: targetFile }).then((url) => {
        this.$set(this.params, "imageUrl", url);
      });
    },
    handleSubmit(type) {
      if (!this.params.channelIds || this.params.channelIds.length === 0) {
        return this.$message.error("请选择推广账号");
      }
      if (!this.params.componentName) {
        return this.$message.error("请输入组件名称");
      }
      if (!this.params.title) {
        return this.$message.error("请输入卡片标题");
      }
      if (!this.params.imageUrl) {
        return this.$message.error("请输入上传图片");
      }
      if (!this.params.buttonText || this.params.buttonText.length < 2) {
        return this.$message.error("请输入正确格式的行动号召");
      }
      if (!this.params.productSellingPoints) {
        return this.$message.error("请输入推广卖点");
      }
      let tmpPoint = this.params.productSellingPoints.split(" ");
      let tmpFlag = tmpPoint.some((item) => {
        return item.length > 9 || item.length < 6;
      });
      if (tmpPoint.length > 10) {
        return this.$message.error("最多可以设置10个卖点");
      }
      if (tmpFlag) {
        return this.$message.error("每个卖点长度必须大于6小于9");
      }
      // return;
      this.submitLoading = true;
      // return;
      if (this.isEdit) {
        updatePromotionCard(this.editId, {
          cards: {
            componentName: this.params.componentName,
            imageUrl: this.params.imageUrl,
            title: this.params.title,
            buttonText: this.params.buttonText,
            productSellingPoints: tmpPoint.join(","),
            enablePersonalAction: this.params.enablePersonalAction,
          },
          channelIds: this.params.channelIds,
          type: type,
        })
          .then((res) => {
            this.$message.success("修改成功");
            this.$emit("close");
            this.$emit("handlePageChange");
          })
          .finally(() => {
            this.submitLoading = false;
          });
      } else {
        this.options = [];
        addPromotionCard({
          cards: {
            componentName: this.params.componentName,
            imageUrl: this.params.imageUrl,
            // imageUrl: "https://h5.file.yayawx.cn/novel_put/2021062128450.jpeg",
            title: this.params.title,
            buttonText: this.params.buttonText,
            productSellingPoints: this.params.productSellingPoints,
            enablePersonalAction: this.params.enablePersonalAction,
          },
          channelIds: this.params.channelIds,
          type: type,
        })
          .then((res) => {
            this.$message.success("新增成功");
            this.$emit("close");
            this.$emit("handlePageChange");
          })
          .finally(() => {
            this.submitLoading = false;
          });
      }
    },
  },
  created() {
    if (this.isEdit) {
      this.params = {
        ...this.editParams,
      };
      let tmpPoint = this.editParams.productSellingPoints.split(",");
      this.$set(this.params, "productSellingPoints", tmpPoint.join(" "));
      this.options = [...this.Foptions];
    }
    if (this.Foptions.length) {
      this.handleSelected(this.Foptions);
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.upload-box {
  width: 108px;
  height: 108px;
  border: 1px dashed #e7e7e7;
  background-color: #f8f8f8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.img-box {
  position: absolute;
  top: 0;
  left: 0;
  img {
    width: 108px;
    height: 108px;
  }
}
</style>